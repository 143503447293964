@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
    font-family: Approach-Light;
    src: url(./assets/fonts/Approach-Light.ttf);
}

@font-face {
    font-family: Approach-Regular;
    src: url(./assets/fonts/Approach-Regular.ttf);
}

@font-face {
    font-family: Approach-RegularItalic;
    src: url(./assets/fonts/Approach-RegularItalic.ttf);
}

@font-face {
    font-family: Approach-SemiBold;
    src: url(./assets/fonts/Approach-SemiBold.ttf);
}

html {
	scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: Approach-Regular;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d1d6cf;
}

h1, h2, h3, h4 {
  font-family: Approach-SemiBold;
  font-weight: normal;
}

p {
  margin-top: 0;
  padding: 0px 0px;
  white-space: pre-wrap;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: underline;
}

blockquote {
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
  font-family: Approach-Regular;
}


main {
  margin: 48px auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
}

section {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  flex: 1;
  margin: 12px 0;
}

section > * {
  flex: 1;
  padding: 0 24px;
}

section ul, li {
  list-style: none;
  margin-left: 0;
}

.gallery {
  aspect-ration: 1/1;
}

.galleryImage {

}

.icons {
  align-self: flex-end;
  margin-top: 2px;
  text-align: right;
}

.icon {
  width: 30px;
  margin-left: 6px;
}

.icon:hover {
  opacity: 0.4;
  animation: rubberBand 1s;
}

.image-gallery-bullets .image-gallery-bullet {
  box-shadow: 0 0px 0px #1a1a1a;
}

.image-gallery-slide .image-gallery-image {
    object-fit: cover;
    aspect-ratio: 1;
}

@media (max-width: 576px) {
  section {
    padding: 0 24px;
    flex-direction: column;
  }
  .events {
    flex-direction: column-reverse;
  }
}
