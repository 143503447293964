.header {
  width: 100vw;
  height: 90vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
}

.logo {
  margin-top: 24px;
  width: 300px;
  justify-self: center;
}

.nav {
  font-family: Approach-Regular;
  font-size: 20px;
  text-transform: uppercase;
  justify-self: flex-start;
  flex: 1;
}

.nav a {
  color: #ffffff;
}

.nav li:last-child {
  margin-left: -8px;
}

.nav li:last-child a {
  border: 1px solid white;
  padding: 3px 8px;
  text-decoration: none;
  background-color: rgba(255,255,255,0.2);
}

.nav li:last-child a:hover {
  border: 1px solid white;
  background-color: rgba(255,255,255,0.6);
  padding: 3px 8px;
  text-decoration: none;
}

.spacer {
  justify-self: flex-end;
  flex: 1;
}

.nav ul {
  margin-bottom: 6px;
}

.language {
  margin-top: 20px;
  margin-right: 40px;
  text-align: right;
  flex: 1;
}

.language a {
  color: #ffffff;
  font-size: 14px;
  margin-right: 12px;
}

.newsletter {
  all: unset;
  cursor: pointer;
  color: #ffffff;
  padding-left: 40px;
}

.newsletter:hover {
  text-decoration: underline;
}

@media (max-width: 576px) {
  .header {
    flex-direction: column-reverse;
  }
  .logo {
    margin-top: 24px;
    width: 300px;
    align-self: center;
    justify-self: center;
  }
  .nav {
    text-align: center;
    margin: auto;
    left: 0;
  }
  .nav ul {
    padding: 0;
  }
  .newsletter {
    padding-left: 0;
  }
  .spacer {
    display: none;
  }
  .language {
    display: block;
    margin-right: 0;
    align-self: center;
    text-align: center;
    flex: 0;
  }
}
